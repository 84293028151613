import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import logo from './thaimarket.png';

class Header extends React.Component {

    render() {
        const rootPath = `${__PATH_PREFIX__}/`
        return (
            <header className="header-main temp-06-h">
                <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light"><div className="container">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {this.props.tagName}
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/ฟอเรกซ">ฟอเร็กซ์</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ภาพรวมการตลาด">ภาพรวมการตลาด</Link>
                            </li>
                            

                        </ul>
                    </div></div>
                </nav>

            </header>
        )
    }
}

export default Header;