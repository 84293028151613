import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import logo from './thaimarket.png';

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link className="navbar-brand header__logo" to="/">
            <img className="logo-header" src={logo} alt="thaimarket" />
            <p>ราคาซื้อขายในตลาดหุ้น & ข่าวการเงิน</p>
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
        >
          <Link className="navbar-brand header__logo" to="/">
                    <img className="web-header__logo" src={logo} alt="thaimarket" />
                </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        <div className="container">
          {children}
        </div>
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
