import React from 'react';
import logo from './thaimarket.png';
import { Link } from "gatsby"

class Footer extends React.Component {

    render() {
        return (
            <div className="f-elementor">
                
                <div className="footer-green">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-xs-12">
                                <div className="row">
                                    <div className="col-md-4 col-xs-12 logo-f">
                                        <img className="f_logo float-right" src={logo} alt="thaimarket" />
                                    </div>
                                    <div className="col-md-8 col-xs-12 f-text-seo">
                                    การเปิดเผยความเสี่ยง: การซื้อขายตราสารทางการเงินและ/หรือเงินดิจิตอลจะมีความเสี่ยงสูงที่รวมถึงความเสี่ยงต่อการสูญเสียจำนวนเงินลงทุนของคุณบางส่วนหรือทั้งหมดและอาจไม่เหมาะสมกับนักลงทุนทั้งหมด ราคาของเงินดิจิตอลแปรปรวนอย่างมากและอาจได้รับผลกระทบจากปัจจัยภายนอกต่าง ๆ เช่น เหตุการณ์ทางการเงิน กฎหมายกำกับดูแล หรือ เหตุการณ์ทางการเมือง การซื้อขายด้วยมาร์จินทำให้ความเสี่ยงทางการเงินเพิ่มขึ้น

                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;