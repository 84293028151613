import React from "react"

class Sidebar extends React.Component {
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12">
          <div className="sidebar sticky-top">
            <div className="widget-right">
              <h3 className="title-widget">	บทวิเคราะห์</h3>
              <ul>
                <li>ฟอเร็กซ์</li>
                <li>ภาพรวมการตลาด</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Sidebar